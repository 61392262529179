import CropperElement from '@cropper/element';
import { CROPPER_GIRD } from '@cropper/utils';
var style = `:host{display:flex;flex-direction:column;position:relative;touch-action:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}:host([bordered]){border:1px dashed var(--theme-color)}:host([covered]){bottom:0;left:0;position:absolute;right:0;top:0}:host>span{display:flex;flex:1}:host>span+span{border-top:1px dashed var(--theme-color)}:host>span>span{flex:1}:host>span>span+span{border-left:1px dashed var(--theme-color)}`;
class CropperGrid extends CropperElement {
  constructor() {
    super(...arguments);
    this.$style = style;
    this.bordered = false;
    this.columns = 3;
    this.covered = false;
    this.rows = 3;
    this.slottable = false;
    this.themeColor = 'rgba(238, 238, 238, 0.5)';
  }
  static get observedAttributes() {
    return super.observedAttributes.concat(['bordered', 'columns', 'covered', 'rows']);
  }
  $propertyChangedCallback(name, oldValue, newValue) {
    if (Object.is(newValue, oldValue)) {
      return;
    }
    super.$propertyChangedCallback(name, oldValue, newValue);
    if (name === 'rows' || name === 'columns') {
      this.$nextTick(() => {
        this.$render();
      });
    }
  }
  connectedCallback() {
    super.connectedCallback();
    this.$render();
  }
  $render() {
    const shadow = this.$getShadowRoot();
    const fragment = document.createDocumentFragment();
    for (let i = 0; i < this.rows; i += 1) {
      const row = document.createElement('span');
      row.setAttribute('role', 'row');
      for (let j = 0; j < this.columns; j += 1) {
        const column = document.createElement('span');
        column.setAttribute('role', 'gridcell');
        row.appendChild(column);
      }
      fragment.appendChild(row);
    }
    if (shadow) {
      shadow.innerHTML = '';
      shadow.appendChild(fragment);
    }
  }
}
CropperGrid.$name = CROPPER_GIRD;
CropperGrid.$version = '2.0.0';
export { CropperGrid as default };