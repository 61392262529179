import {inject, Injectable} from '@angular/core';
import {DemandeursApiService} from '@models/demandeurs/demandeurs.api.service';
import CDemandeurs from '@models/demandeurs/collection/demandeurs.collection.model';
import {Observable} from 'rxjs';
import {
    ICDemandeursQueryParameters, ICDemandeursApi
} from '@models/demandeurs/collection/demandeurs.collection.interfaces';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {DemandeursFactory} from '@models/demandeurs/demandeurs.factory';

@Injectable({providedIn: 'root'})
export class CDemandeursFactory {
    private _demandeursApiService = inject(DemandeursApiService);
    private _demandeursFactory = inject(DemandeursFactory);

    create(cDemandeursApi: ICDemandeursApi): CDemandeurs {
        const cDemandeurs = new CDemandeurs();

        cDemandeurs.links = new Links(cDemandeursApi._links);
        cDemandeurs.page = cDemandeursApi.page;
        cDemandeurs.pages = cDemandeursApi.pages;
        cDemandeurs.perPage = cDemandeursApi.limit;
        cDemandeurs.total = cDemandeursApi.total;
        if (cDemandeursApi._embedded) {
            cDemandeurs.results = this._demandeursFactory.create(cDemandeursApi._embedded.items);
        }

        return cDemandeurs;
    }

    get$(queryParameters?: ICDemandeursQueryParameters): Observable<CDemandeurs> {
        return this._demandeursApiService.getEtudeCollection$(queryParameters).pipe(map(cDemandeursApi => this.create(cDemandeursApi)));
    }

    getNext$(cDemandeurs: CDemandeurs): Observable<CDemandeurs> {
        return this._demandeursApiService.getCollectionByLink$(cDemandeurs.links.next).pipe(map(cDemandeursApi => this.create(cDemandeursApi)));
    }
}
