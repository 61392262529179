import {inject, Injectable} from '@angular/core';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {IDemandeurApi} from '@models/demandeurs/demandeur/demandeur.interfaces';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';

@Injectable({providedIn: 'root'})
export class DemandeursFactory {
    private _demandeurFactory = inject(DemandeurFactory);

    create(demandeurApis: IDemandeurApi[] = []): Demandeur[] {
        return (demandeurApis || []).filter(demandeurApi => demandeurApi)
            .map(demandeurApi => this._demandeurFactory.create(demandeurApi));
    }
}
