import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output, output, ViewChild, ViewEncapsulation
} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {FormsModule, NgModel} from '@angular/forms';
import {IFormSelectInputOptions} from '@shared/form/form.interfaces';
import FormService from '@shared/form/form.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {SearchHighlightDirective} from '@shared/search/highlight/search.highlight.directive';
import {NgOptimizedImage, NgTemplateOutlet} from '@angular/common';
import {ProvideParentFormDirective} from '@shared/form/provide-parent-form.directive';

@Component({
    /* eslint-disable */
    encapsulation: ViewEncapsulation.None,
    /* eslint-enable */
    exportAs: 'formSelectInput',
    imports: [
        FormsModule,
        NgOptimizedImage,
        NgSelectModule,
        NgTemplateOutlet,
        ProvideParentFormDirective,
        SearchHighlightDirective,
    ],
    selector: 'app-form-select-input',
    styleUrls: ['form.select-input.component.scss'],
    templateUrl: 'form.select-input.component.html',
})
export class AppFormSelectInputComponent implements OnDestroy, OnInit {
    // @todo Réfléchir à modifier le nom de cet attribut
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() search = new EventEmitter<string>();
    // Impossible de passer avec la ligne ci-dessous, une erreur dans la console apparait
    // Readonly search = output<string>();
    static readonly customTemplates = {COMMUNE: 'commune', USER: 'user'};
    static readonly initFormSelectInputOptions: IFormSelectInputOptions = {
        bindLabel: 'label',
        disabled: false,
        initWithEmptySearch: false,
        loadingText: 'Chargement des données',
        multiple: false,
        name: 'formSelectInput',
        notFoundText: 'Aucun item trouvé',
        placeholder: 'Rechercher',
        required: false,
        searchable: true,
        typeToSearchText: 'Saisissez une recherche',
        virtualScroll: true,
        groupBy: undefined!,
        groupValue: undefined!,
    };
    readonly selected = output<unknown>();
    private _formInput!: NgModel;
    private _isInvalid = false;
    private _list: unknown[] = [];
    private _loading = false;
    private _model!: unknown;
    private _ngModelFormSource = new ReplaySubject<NgModel>();
    private _ngModelForm$ = this._ngModelFormSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IFormSelectInputOptions = {...AppFormSelectInputComponent.initFormSelectInputOptions};
    // @todo Renommer en Source et vérifier si il ne faudrait pas ensuite quand même un search$
    private _search$ = new Subject<string>();

    get customTemplates(): Record<string, string> {
        return AppFormSelectInputComponent.customTemplates;
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    @ViewChild('formNgSelect')
    set formNgSelect(value: NgModel) {
        setTimeout(_ => {
            this._formInput = value;
            this._ngModelFormSource.next(this._formInput);
        }, 1);
    }

    get isInvalid(): boolean {
        return this._isInvalid;
    }

    get list(): unknown[] {
        return this._list;
    }

    @Input()
    set list(value: unknown[]) {
        this._list = value;
    }

    get loading(): boolean {
        return this._loading;
    }

    @Input()
    set loading(value: boolean) {
        this._loading = value;
    }

    get model(): unknown {
        return this._model;
    }

    @Input()
    set model(value: unknown) {
        this._model = value;
    }

    get options(): IFormSelectInputOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectInputOptions) {
        this._options = {...AppFormSelectInputComponent.initFormSelectInputOptions, ...value};
    }

    get search$(): Subject<string> {
        return this._search$;
    }

    ngOnInit(): void {
        FormService.getError$(this._ngModelForm$, () => this._isInvalid = false).pipe(
            map(error => !!error),
            takeUntil(this._onDestroy$),
        ).subscribe(isInvalid => this._isInvalid = isInvalid);
        this.search$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter(search => !!search),
            takeUntil(this._onDestroy$),
        ).subscribe(search => this.search.emit(search));
        if (this._options.initWithEmptySearch) {
            setTimeout(() => this.search.emit(undefined));
        }
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clear(): void {
        this._model = undefined!;
        this.onSelect();
    }

    onSelect(): void {
        this.selected.emit(this._model);
    }
}
