import { dsnToString } from '../utils-hoist/dsn.js';
import '../utils-hoist/logger.js';
import '../utils-hoist/debug-build.js';
import '../utils-hoist/time.js';
import '../utils-hoist/syncpromise.js';
import { createEnvelope } from '../utils-hoist/envelope.js';

/**
 * Creates OTEL log envelope item for a serialized OTEL log.
 *
 * @param log - The serialized OTEL log to include in the envelope.
 * @returns The created OTEL log envelope item.
 */
function createOtelLogEnvelopeItem(log) {
  return [{
    type: 'otel_log'
  }, log];
}

/**
 * Creates an envelope for a list of logs.
 *
 * @param logs - The logs to include in the envelope.
 * @param metadata - The metadata to include in the envelope.
 * @param tunnel - The tunnel to include in the envelope.
 * @param dsn - The DSN to include in the envelope.
 * @returns The created envelope.
 */
function createOtelLogEnvelope(logs, metadata, tunnel, dsn) {
  const headers = {};
  if (metadata?.sdk) {
    headers.sdk = {
      name: metadata.sdk.name,
      version: metadata.sdk.version
    };
  }
  if (!!tunnel && !!dsn) {
    headers.dsn = dsnToString(dsn);
  }
  return createEnvelope(headers, logs.map(createOtelLogEnvelopeItem));
}
export { createOtelLogEnvelope, createOtelLogEnvelopeItem };
