import {NgModule} from '@angular/core';
import {AppBienEditLocalisationComponent} from '@features/bien-edit/localisation/bien.edit-localisation.component';
import {AppBienEditConstraintsDirective} from '@features/bien-edit/constraints/bien-edit.constraints.directive';
import {AppBienEditDescriptionComponent} from '@features/bien-edit/description/bien.edit-description.component';
import {AppBienEditDossierComponent} from '@features/bien-edit/dossier/bien.edit-dossier.component';
import {
    AppBienEditViabilisationAssainissementComponent
} from '@features/bien-edit/viabilisation-assainissement/bien.edit-viabilisation-assainissement.component';

@NgModule({
    exports: [
        AppBienEditConstraintsDirective,
        AppBienEditDescriptionComponent,
        AppBienEditDossierComponent,
        AppBienEditLocalisationComponent,
        AppBienEditViabilisationAssainissementComponent,
    ],
    imports: [
        AppBienEditConstraintsDirective,
        AppBienEditDescriptionComponent,
        AppBienEditDossierComponent,
        AppBienEditLocalisationComponent,
        AppBienEditViabilisationAssainissementComponent,
    ],
})
export class BienEditModule {
}
